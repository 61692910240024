.checkbox {
  display: flex;
  align-items: center;
  padding: .5rem .5rem .5rem 0;
  display: inline-flex;

  input {
    width: 1.25rem;
    height: 1.25rem;
  }
  input:hover{
    cursor: pointer;
  }

  label {
    padding-left: .5rem;
    user-select: none;
    margin-left: auto;
  }
}
