.customersList {
  padding: .5rem;
  height: 100%;

  .red {
    color:red;
  }

  .green {
    color:green;
  }
}