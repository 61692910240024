button.button {
  background: #00334E;
  color: #fff;
  border: none;
  padding: 0.5rem;
  user-select: none;
  cursor: pointer;
  outline: none;
  appearance: none;

  &.small {
    line-height: 1rem;
    padding: 5px 20px;
    font-weight: bold;
  }

  &.light {
    background: #e4e4d8;
    padding: 0.5rem 1rem;
    color: #222;
    font-size: 0.75rem;
    font-weight: bold;
    margin: 0.25rem;
    display: inline-block;
    line-height: inherit;
  }

  &.tiny {
    line-height: .75rem;
    padding: .25rem .5rem;
    font-size: 12px;
  }

  &:disabled {
    background: rgb(170, 170, 170);
  }
}