.pageHeader {
  background-color: #00334E;
  display: flex;
  margin-bottom: 1rem;
  font-size: 20px;
  color: #fff;
  text-align: center;

  .title, .feature {
    padding: .5rem;
  }

  .title {
    // flex-basis: 75%;
  }

  .feature {
    background-color: #20BECD;
    // flex-basis: 25%;
  }
}