.radioButton{  
  display: flex;
  align-items: center;
  padding: .5rem .5rem .5rem 0;
  
  input {
    width: 1.25rem;
    height: 1.25rem;
    box-shadow: none;
    margin-right: .5rem;
  }

  label {
    cursor: pointer;
  }
}