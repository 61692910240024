.defaultTextArea {
  width: 100%;
  
  textarea {
    width: 100%;
    resize: vertical;
    &.error {
      border-color: red;
    }
  }
  input:disabled {
    background-color: #eee;
  }
  label {
    display: block;
    margin-bottom: 5px;
  }
  .errormsg {
    color: red;
  }
}