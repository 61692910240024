.messageBox {
  .floatingContainer {
    min-width: 300px;
  }

  .messageContent {
    font-size: 18px;
    margin-bottom: 1rem;
  }

  .buttons {
    > :first-child {
      margin-right: 2rem;
    }
  }
}